import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
//import "./components/gmaps";
//import "./components/googleMaps";
import "./components/listingTitleUrl";
//import "./components/filterSubmit";
import "./components/getQueryVar";
import "./components/fetchListings";
import "./components/formInputs";


Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(intersect)
window.Alpine = Alpine
Alpine.start()
