window.listingTitle = function (UnitNumber, StreetNumber, StreetName, StreetSuffix) {
    unit = Number.isInteger(parseInt(UnitNumber)) ? '#' + UnitNumber : UnitNumber
    title = (unit || '') + ' ' + (StreetNumber || '') + ' ' + (StreetName || '') + ' ' + (StreetSuffix || '')
    return title
}

window.listingUrl = function (UnitNumber, StreetNumber, StreetName, StreetSuffix, City, ListingId) {
    unit = UnitNumber ? UnitNumber + '-' : ''
    address = unit + StreetNumber + '-' + StreetName + '-' + StreetSuffix
    addressUrlSlug = address.replace(/\#/g, '').toLowerCase()
    locationUrlSlug = City.replace(/\s+/g, '-').toLowerCase()
    url = locationUrlSlug + '/' + addressUrlSlug + '-' + ListingId + '/'
    return url
}