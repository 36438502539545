window.rangeInput = function () {
    return {
        minPrice: 1000,
        maxPrice: 7000,
        rangeMin: 100,
        rangeMax: 10000,
        rangeMinThumb: 0,
        rangeMaxThumb: 0,

        mintrigger() {
            this.minPrice = Math.min(this.minPrice, this.maxPrice - 500);
            this.rangeMinThumb = ((this.minPrice - this.rangeMin) / (this.rangeMax - this.rangeMin)) * 100;
        },

        maxtrigger() {
            this.maxPrice = Math.max(this.maxPrice, this.minPrice + 500);
            this.rangeMaxThumb = 100 - (((this.maxPrice - this.rangeMin) / (this.rangeMax - this.rangeMin)) * 100);
        },
    }
}